<template>
    <div
        v-if="isShowing"
        ref="interactElement"
        :class="{
            isAnimating: isInteractAnimating,
            isCurrent: isCurrent,
        }"
        class="card"
        :style="{ transform: transformString }"
    >
        <div class="flex justify-center gap-4" v-if="blockOnLargerScreen">
            <img class="w-[150px] md:w-[215px] image-card" :class="{ 'is-selected': typeSelected == ACCEPT_CARD }" :src="card.image_accept" @click="clickCard(ACCEPT_CARD)" />
            <img class="w-[150px] md:w-[215px] image-card" :class="{ 'is-selected': typeSelected == REJECT_CARD }" :src="card.image_reject" @click="clickCard(REJECT_CARD)" />
        </div>
        <div class="flex justify-center gap-4" v-else>
            <img class="w-[150px] md:w-[215px]" :src="card.image_accept" @click="clickCard(ACCEPT_CARD)" />
            <img class="w-[150px] md:w-[215px]" :src="card.image_reject" @click="clickCard(REJECT_CARD)" />
        </div>
    </div>
</template>

<script>
import interact from "interactjs";
const ACCEPT_CARD = "cardAccepted";
const REJECT_CARD = "cardRejected";
const SKIP_CARD = "cardSkipped";

export default {
    static: {
        interactMaxRotation: 15,
        interactOutOfSightXCoordinate: 500,
        interactOutOfSightYCoordinate: 600,
        interactYThreshold: 150,
        interactXThreshold: 100,
    },

    props: {
        card: {
            type: Object,
            required: true,
        },
        isCurrent: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            blockOnLargerScreen: false,
            isShowing: true,
            isInteractAnimating: true,
            isInteractDragged: null,
            interactPosition: {
                x: 0,
                y: 0,
                rotation: 0,
            },
            typeSelected: null,
            ACCEPT_CARD: ACCEPT_CARD,
            REJECT_CARD: REJECT_CARD,
            SKIP_CARD: SKIP_CARD,
        };
    },

    computed: {
        transformString() {
            if (!this.isInteractAnimating || this.isInteractDragged) {
                const { x, y, rotation } = this.interactPosition;
                return `translate3D(${x}px, ${y}px, 0) rotate(${rotation}deg)`;
            }

            return null;
        },
    },

    mounted() {
        window.addEventListener("resize", this.screenSize);
        this.typeSelected = null;
        this.screenSize();
        const element = this.$refs.interactElement;

        interact(element).draggable({
            onstart: () => {
                if (this.blockOnLargerScreen) return;
                this.isInteractAnimating = false;
            },

            onmove: (event) => {
                if (this.blockOnLargerScreen) return;
                const { interactMaxRotation, interactXThreshold } = this.$options.static;

                const x = this.interactPosition.x + event.dx;
                const y = this.interactPosition.y + event.dy;

                let rotation = interactMaxRotation * (x / interactXThreshold);

                if (rotation > interactMaxRotation) rotation = interactMaxRotation;
                else if (rotation < -interactMaxRotation) rotation = -interactMaxRotation;

                this.interactSetPosition({ x, y, rotation });
            },

            onend: () => {
                if (this.blockOnLargerScreen) return;
                const { x, y } = this.interactPosition;
                const { interactXThreshold, interactYThreshold } = this.$options.static;
                this.isInteractAnimating = true;

                if (x > interactXThreshold) this.playCard(REJECT_CARD);
                else if (x < -interactXThreshold) this.playCard(ACCEPT_CARD);
                // else if (y > interactYThreshold) this.playCard(SKIP_CARD);
                else this.resetCardPosition();
            },
        });
    },

    beforeDestroy() {
        interact(this.$refs.interactElement).unset();
    },

    methods: {
        screenSize() {
            let vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

            if (vw >= 768) {
                this.blockOnLargerScreen = true;
                return;
            }

            this.blockOnLargerScreen = false;
        },

        hideCard() {
            setTimeout(() => {
                this.isShowing = false;
                this.$emit("hideCard", this.card);
            }, 300);
        },

        playCard(interaction) {
            const { interactOutOfSightXCoordinate, interactOutOfSightYCoordinate, interactMaxRotation } = this.$options.static;

            this.interactUnsetElement();

            switch (interaction) {
                case ACCEPT_CARD:
                    this.interactSetPosition({
                        x: -interactOutOfSightXCoordinate,
                        rotation: -interactMaxRotation,
                    });

                    this.$emit(ACCEPT_CARD);
                    break;

                case REJECT_CARD:
                    this.interactSetPosition({
                        x: interactOutOfSightXCoordinate,
                        rotation: interactMaxRotation,
                    });

                    this.$emit(REJECT_CARD);
                    break;

                case SKIP_CARD:
                    this.interactSetPosition({
                        y: interactOutOfSightYCoordinate,
                    });

                    this.$emit(SKIP_CARD);
                    break;
            }

            this.hideCard();
        },

        interactSetPosition(coordinates) {
            const { x = 0, y = 0, rotation = 0 } = coordinates;
            this.interactPosition = { x, y, rotation };
        },

        interactUnsetElement() {
            interact(this.$refs.interactElement).unset();
            this.isInteractDragged = true;
        },

        resetCardPosition() {
            this.interactSetPosition({ x: 0, y: 0, rotation: 0 });
        },
        clickCard(type) {
            this.typeSelected = type;
            setTimeout(() => {
                this.playCard(type);
            }, 500);
            //
        },
    },
};
</script>

<style lang="scss" scoped>
$ease-out-back: cubic-bezier(0.175, 0.885, 0.32, 1.275);
$cardsTotal: 1;
$cardsWidth: 300px;
$cardsPositionOffset: 55vh * 0.06;
$cardsScaleOffset: 0.08;
$defaultTranslation: $cardsPositionOffset * $cardsTotal;
$defaultScale: 1 - ($cardsScaleOffset * $cardsTotal);
$fs-card-title: 1.125em;

.image-card {
    cursor: pointer;
    position: relative;
    filter: drop-shadow(0px 16px 32px rgba(0, 0, 0, 0.15));
}
.image-card.is-selected {
    transform: translateY(-15px);
    filter: drop-shadow(2px 20px 9px rgba(0, 0, 0, 0.3));
    transition: all 0.3s ease-in-out;
}

.card {
    position: absolute;
    top: 0;
    width: 100%;
    // height: 284px;
    // display: flex;
    margin: auto;

    opacity: 0;
    transform: translateY($defaultTranslation) scale($defaultScale);
    transform-origin: 50%, 100%;
    // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.4);
    // user-select: none;
    // pointer-events: none;
    // will-change: transform, opacity;

    &.isCurrent {
        touch-action: none;
        // pointer-events: none;
    }

    &.isAnimating {
        transition: transform 0.7s $ease-out-back;
    }
}

@media (min-width: 768px) {
    .card {
        // width: 329px;
        // height: 404px;
    }
}

@for $i from 1 through $cardsTotal {
    $index: $i - 1;
    $translation: $cardsPositionOffset * $index;
    $scale: 1 - ($cardsScaleOffset * $index);

    .card:nth-child(#{$i}) {
        z-index: $cardsTotal - $index;
        opacity: 1;
        transform: translateY($translation) scale($scale);
    }
}
</style>
