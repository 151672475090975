<template>
    <transition name="fade">
        <div class="fixed inset-0 flex items-center justify-center">
            <div class="optin-overlay overflow-auto fixed inset-0 z-100 flex items-center justify-center p-5">
                <div class="relative max-w-3xl mx-auto">
                    <div class="w-full md:shadow-md md:p-0">
                        <div class="md:min-w-full md:mx-auto p-2 bg-blue-600">
                            <div class="md:p-8 w-full md:border-2 border-white border md:p-1 md:py-8 p-5">
                                <div class="flex">
                                    <CheckboxField
                                        class="text-left text-[14px] mb-6 leading-normal text-white"
                                        v-model="isChecked"
                                        id="checkConsent"
                                        @update:modelValue="handleCheckboxChange"
                                        label="Autorizo a utilização de meus dados pessoais (nome, e-mail e telefone) pela Sony Music para o envio de novidades e campanhas de mídia relacionadas aos projetos e artistas nacionais e internacionais do Cast Sony Music, seja no Brasil ou exterior, via redes sociais, Google, SMS e aplicativos de mensagens. Confirmo ainda que tenho/sou maior de 18 anos. Essa assinatura poderá ser cancelada a qualquer momento."
                                    />
                                </div>
                                <button class="btn shadow-none is-outline max-w-[190px] mx-auto px-4 flex flex-nowrap justify-center items-center gap-2 grow hover:border-2 hover:border-solid hover:text-orange hover:bg-blue-800" @click="closePopup" aria-label="Edit">
                                    <span class="text-[16px] leading-normal normal-case"> Comece aqui </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
import { defineEmits, ref, computed } from "vue";

import CheckboxField from "@/components/CheckboxField.vue";

const isChecked = ref(false);

const emit = defineEmits("optin:close");

const handleCheckboxChange = (value) => {
    isChecked.value = value;
    console.log("Optin:handleCheckboxChange", isChecked.value);
};

const closePopup = () => {
    console.log("Optin:closePopup", isChecked.value);
    emit("optin:close", isChecked.value);
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.optin-overlay {
    background-color: rgba(0, 0, 0, 0.7);
}
</style>
