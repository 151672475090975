<template>
    <div class="app-container">
        <AppHeader></AppHeader>
        <div class="flex flex-col justify-center items-center max-w-[680px] mx-auto px-4 pt-[70px] md:pt-[90px]">
            <h1 class="uppercase leading-tight font-anton text-26 md:text-36 text-center mb-3 md:mb-4">
                Descubra agora que <br class="md:hidden" />
                playlist do <br class="hidden md:block" />Roberto Carlos <br class="md:hidden" />
                combina mais com você
            </h1>

            <img class="mb-3 md:mb-5" src="@/assets/images/sep.png" />

            <p class="text-center max-w-[290px] md:max-w-[560px] mx-auto mb-5 md:mb-9 md:text-22 md:hidden">Transforme suas preferências em uma playlist perfeita para você arrastando para o lado.</p>
            <p class="text-center max-w-[290px] md:max-w-[560px] mx-auto mb-5 md:mb-9 md:text-22 hidden md:block">Transforme suas preferências em uma playlist perfeita para você clicando na imagem.</p>

            <button @click="login('spotify')" class="btn is-spotify mb-3">Logar com Spotify</button>
            <button @click="login('deezer')" class="btn is-deezer mb-6">Logar com Deezer</button>
        </div>
        <transition name="fade">
            <OptInPopup v-if="showPopup" :isChecked="consentOptin" @optin:close="closePopup" />
        </transition>
    </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useAuthStore } from "@/stores/auth";

import AppHeader from "@/components/AppHeader.vue";
import OptInPopup from "@/components/OptInPopup.vue";

const authStore = useAuthStore();
const showPopup = ref(false);
const consentOptin = ref(false);

const openPopup = () => {
    if (!localStorage.hasOwnProperty("consent-optin")) {
        showPopup.value = true;
    }
};

const closePopup = (value) => {
    consentOptin.value = value;
    localStorage.setItem("consent-optin", consentOptin.value);
    showPopup.value = false;
};

onMounted(() => {
    const checkIfLoaded = () => {
        if (document.readyState === "complete") {
            openPopup();
        } else {
            setTimeout(checkIfLoaded, 2000);
        }
    };
    checkIfLoaded();
});

const login = (provider) => {
    if (provider === "spotify") {
        authStore.loginWithSpotify();
    } else if (provider === "deezer") {
        authStore.loginWithDeezer();
    }
};
</script>
