<template>
    <header class="bg-header pt-3 flex justify-center border-b-2 border-white">
        <div class="relative px-4">
            <picture>
                <source srcset="@/assets/images/top__desktop.png" media="(min-width: 768px)" />
                <img alt="Roberto Carlos" src="@/assets/images/top.png" />
            </picture>
            <transition name="fade">
                <picture v-if="props.showIcon" class="absolute bottom-[-36px] md:bottom-[-50px] left-0">
                    <source srcset="@/assets/images/flag-quiz__desktop.png" media="(min-width: 768px)" />
                    <img alt="Quiz" src="@/assets/images/flag-quiz.png" />
                </picture>
            </transition>
        </div>
    </header>
</template>

<script setup>
import { defineProps } from "vue";

const props = defineProps({
    showIcon: { type: Boolean, default: true },
});
</script>

<style>
.bg-header {
    @apply bg-blue-800;
    background: linear-gradient(272deg, #122e85 0%, #214ac6 52.37%, #bce3fc 110.25%), #122e85;
}

@media (min-width: 768px) {
    .bg-header {
        background: linear-gradient(273deg, #122e85 0%, #214ac6 47.88%, #bce3fc 100.79%);
    }
}
</style>
