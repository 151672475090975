<template>
    <div class="relative bg-white rounded-lg p-[2px] pr-[25px] md:pr-[35px] shadow-[0_10px_17px_0px_rgba(0,0,0,0.2)] md:shadow-[0_10px_15px_-8px_rgba(0,0,0,0.8)]">
        <div class="bg-blue-600 h-[8px] md:h-[14px] rounded-lg transition-all" :style="{ width: progress + '%' }"></div>
        <p class="absolute right-0 top-[-8px] md:top-[-12px] flex items-center justify-center border border-white border-2 bg-blue-600 h-[28px] w-[28px] md:h-[40px] md:w-[40px] rounded-xl text-white text-12 md:text-16 font-anton">{{ count }}</p>
    </div>
</template>

<script setup>
import { defineProps, computed } from "vue";
const props = defineProps({
    count: { type: Number, default: 0 },
    max: { type: Number, default: 0 },
});

const progress = computed(() => {
    return (props.count / props.max) * 100;
});
</script>
