import { defineStore } from "pinia";
import Cookies from "js-cookie";

import { callApi } from "@/utils/http";

const API_URI = import.meta.env.VITE_API_URL;

export const useAuthStore = defineStore("auth", {
    state: () => ({
        user: {
            id: "",
            name: "",
            email: "",
            phone: "",
            city: "",
            state: "",
            birthday: "",
            authType: "spotify",
            consentGiven: false,
            status: "unregistered",
        },
        isLoggedIn: false,
        accessToken: "",
        errorMessage: "",
    }),

    actions: {
        generateRandomString(length) {
            let text = "";
            const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (let i = 0; i < length; i++) {
                text += possible.charAt(Math.floor(Math.random() * possible.length));
            }
            return text;
        },

        async loginWithSpotify() {
            try {
                const { VITE_SPOTIFY_CLIENT_ID, VITE_SPOTIFY_REDIRECT_URI, VITE_SPOTIFY_SCOPE } = import.meta.env;
                const appId = VITE_SPOTIFY_CLIENT_ID;
                const redirectUri = VITE_SPOTIFY_REDIRECT_URI;
                const permissions = VITE_SPOTIFY_SCOPE ?? "user-read-private user-read-email playlist-modify-private";
                console.log(VITE_SPOTIFY_REDIRECT_URI);
                const scope = encodeURIComponent(permissions);
                const state = this.generateRandomString(16);
                const query = `client_id=${appId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=token&show_dialog=true&state=${state}`;
                window.location.href = `https://accounts.spotify.com/authorize?${query}`;
            } catch (error) {
                this.handleError("Erro ao iniciar login com Spotify. Por favor, tente novamente mais tarde.", error);
            }
        },

        async authWithSpotify(accessToken, router) {
            try {
                const consentOptin = localStorage.getItem("consent-optin") ?? false;
                const state = this.generateRandomString(16);
                const response = await callApi(`/auth/spotify?access_token=${accessToken}&state=${state}&consent_given=${consentOptin}`);
                // console.log('auth.authWithSpotify', response.data.access_token);
                this.setAccessToken(response.access_token);
                // console.log('auth.authWithSpotify', response.data.data);
                return this.setUser(response.data);
            } catch (error) {
                this.handleError("Erro ao iniciar login com Deezer. Por favor, tente novamente mais tarde.", error);
            }
        },

        async loginWithDeezer() {
            try {
                const { VITE_DEEZER_CLIENT_ID, VITE_DEEZER_REDIRECT_URI, VITE_DEEZER_SCOPE } = import.meta.env;
                const appId = VITE_DEEZER_CLIENT_ID;
                const redirectUri = VITE_DEEZER_REDIRECT_URI;
                const perms = VITE_DEEZER_SCOPE ?? "basic_access,email,manage_library";
                const query = `app_id=${appId}&redirect_uri=${redirectUri}&perms=${perms}`;
                window.location.href = `https://connect.deezer.com/oauth/auth.php?${query}`;
            } catch (error) {
                this.handleError("Erro ao iniciar login com Deezer. Por favor, tente novamente mais tarde.", error);
            }
        },

        async authWithDeezer(code, router) {
            try {
                const { VITE_DEEZER_CLIENT_ID, VITE_DEEZER_CLIENT_SECRET } = import.meta.env;
                const appId = VITE_DEEZER_CLIENT_ID;
                const appSecret = VITE_DEEZER_CLIENT_SECRET;
                const consentOptin = localStorage.getItem("consent-optin") ?? false;
                const state = this.generateRandomString(16);
                const query = `app_id=${appId}&secret=${appSecret}&code=${code}&state=${state}&consent_given=${consentOptin}`;
                const response = await callApi(`/auth/deezer?${query}`);
                this.setAccessToken(response.access_tooken);
                return this.setUser(response.data);
            } catch (error) {
                this.handleError("Erro ao trocar o código de autorização por token de acesso do Deezer. Por favor, tente novamente mais tarde.", error);
            }
        },

        async setUser(userData) {
            // console.log('auth.setUser', userData);
            this.user = {
                id: userData.id,
                name: userData.name,
                phone: userData.phone,
                email: userData.email,
                city: userData.city,
                state: userData.state,
                birthday: userData.birthday,
                authType: userData.authentication_type,
                consentGiven: userData.consentGiven,
                status: userData.status,
            };
            this.isLoggedIn = true;
            return this.user;
        },

        setAccessToken(newToken) {
            // console.log('auth.setAccessToken', newToken);
            this.accessToken = newToken;
            this.saveAccessToken();
        },

        async userIsLoggedIn() {
            if (!this.isLoggedIn) {
                this.accessToken = this.getSavedAccessToken();
                if (this.accessToken) {
                    const user = await this.getUserInfo();
                    // console.log('auth.userIsLoggedIn', this.user);
                }
            }
            return this.isLoggedIn;
        },

        async getUserInfo() {
            try {
                const response = await callApi("/users");
                //console.log('getUserInfo', response);
                const userData = response?.data;
                const AccessToken = response?.access_token;
                if (AccessToken) {
                    this.setAccessToken(AccessToken);
                }
                if (userData) {
                    return await this.setUser(userData);
                }
            } catch (error) {
                this.handleError("Erro ao buscar perfil.", error);
            }
        },

        async saveProfile() {
            try {
                const data = this.user;
                const response = await callApi("/users", "PUT", data);
                this.setUser(response.data);
                return true;
            } catch (error) {
                throw error;
                // this.handleError("Erro ao salvar o perfil. Tente novamente.", response);
            }
        },

        saveAccessToken() {
            return Cookies.set("__cktkvx", this.accessToken, { secure: true, sameSite: "strict", expires: 1 });
        },

        getSavedAccessToken() {
            try {
                return Cookies.get("__cktkvx");
            } catch (error) {
                this.handleError("Erro ao obter cookie:", error);
            }
        },

        async runOnAppStart() {
            return await this.userIsLoggedIn();
        },

        handleError(message, error) {
            console.error("handleError", message, error);
            this.errorMessage = message;
            throw new Error(message);
        },
    },
});
