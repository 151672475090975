import { createRouter, createWebHistory, createWebHashHistory } from "vue-router";
import { useAuthStore } from "@/stores/auth";

import Home from "@/views/Home.vue";
import Quiz from "@/views/Quiz.vue";
import SpotifyCallback from "@/views/SpotifyCallback.vue";
import DeezerCallback from "@/views/DeezerCallback.vue";

const routes = [
    { path: "/", name: "Home", component: Home },
    { path: "/quiz/", name: "Quiz", component: Quiz, meta: { requiresAuthentication: true } },
    { path: "/auth/spotify/callback/", name: "SpotifyCallback", component: SpotifyCallback },
    { path: "/auth/deezer/callback/", name: "DeezerCallback", component: DeezerCallback },
];

const router = createRouter({
    //history: createWebHashHistory(),
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

function hasListAccessCode() {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");
    return !!code;
}

function isLoggedIn() {
    const authStore = useAuthStore();
    console.log("Router.isLoggedIn", authStore.isLoggedIn);
    return authStore.isLoggedIn;
}

function isRegistered() {
    const authStore = useAuthStore();
    console.log("Router.isRegistered", authStore.user.status);
    return authStore.user.status === "registered";
}

router.beforeEach(async (to, from, next) => {
    try {
        if (to.matched.some((record) => record.meta.requiresCode)) {
            if (!hasListAccessCode()) {
                next({ name: "Home" });
            } else {
                next();
            }
        } else {
            if (to.matched.some((record) => record.meta.requiresAuthentication) && !isLoggedIn()) {
                next({ name: "Home" });
            } else {
                if (to.matched.some((record) => record.meta.requiresRegistration) && !isRegistered()) {
                    next({ name: "Profile" });
                } else {
                    next();
                }
            }
        }
    } catch (error) {
        console.error("Erro ao verificar as condições da rota:", error);
        next({ name: "Home" });
    }
});

export default router;
