// http.js

import axios from "axios";

import { useAuthStore } from "@/stores/auth";

const API_URI = import.meta.env.VITE_API_URL;

export async function callApi(endpoint, method = "GET", data = null, options = {}) {

    const authStore = useAuthStore();

    const accessToken = authStore.accessToken;

    try {
        if (accessToken) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${accessToken}`,
            };
        }

        const config = {
            method: method,
            url: `${API_URI}${endpoint}`,
            data: data,
            ...options,
        };

        const response = await axios(config);
        return response.data;

    } catch (error) {

        if (error.response) {
            console.error("Erro na requisição:", error.response.status);
            throw error;
            // throw new Error(`Erro na requisição: ${error.response.status}`);
        } else if (error.request) {
            console.error("Erro na requisição: Sem resposta do servidor");
            throw error;
            // throw new Error("Erro na requisição: Sem resposta do servidor");
        } else {
            console.error("Erro ao enviar a requisição:", error.message);
            throw error;
            // throw new Error(`Erro ao enviar a requisição: ${error.message}`);
        }
    }
}
