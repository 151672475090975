<template>
    <div class="flex justify-center items-center h-screen bg-black" v-if="isLoading">
        <span class="text-white text-xl">Autenticando...</span>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";

const authStore = useAuthStore();
const router = useRouter();
const isLoading = ref(true);

onMounted(async () => {
    const code = new URLSearchParams(window.location.search).get("code");

    if (code) {
        console.error("Código de autorização ou token de acesso não encontrado na URL.");
        await router.push("/");
    }

    try {
        const user = await authStore.authWithDeezer(code, router);

        await router.push("/quiz");
    } catch (error) {
        console.error("Erro ao obter informações do usuário:", error);
        await router.push("/");
    }

    isLoading.value = false;
});
</script>
