import { createApp } from "vue";
import { createPinia } from "pinia";
import vueFeather from "vue-feather";
import App from "@/App.vue";
import router from "@/router";

import "@/assets/css/index.css";
console.log(import.meta.env.VITE_DATA_URL);
const app = createApp(App);

app.use(createPinia());

app.component(vueFeather.name, vueFeather);

app.use(router);
app.mount("#app");

app.config.errorHandler = (err, instance, info) => {
    console.error(`Error: ${err.toString()}\nInfo: ${info}`);
};
