<template>
    <div class="cards">
        <GameCard v-for="(card, index) in cards" :key="card.image_accept" :card="card" :is-current="index === 0" @cardAccepted="$emit('vote', { index: index, direction: 'accept' })" @cardRejected="$emit('vote', { index: index, direction: 'reject' })" @hideCard="$emit('hideCard')"> </GameCard>
    </div>
</template>

<script>
import GameCard from "@/components/fato-ou-fake/GameCard.vue";
export default {
    components: {
        GameCard,
    },

    props: {
        cards: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
.cards {
    position: relative;
    width: 100%;
}
</style>
