<template>
    <div class="flex justify-center items-center h-screen bg-black" v-if="isLoading">
        <span class="text-white text-xl">Autenticando...</span>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";

const router = useRouter();

const authStore = useAuthStore();
const isLoading = ref(true);

onMounted(async () => {
    const hash = window.location.hash.substr(1);
    const params = new URLSearchParams(hash);
    const accessToken = params.get("access_token");

    if (!accessToken) {
        console.error("SpotifyCallback.vue", "Token de acesso não encontrado.");
        await router.push("/");
    }

    try {
        const user = await authStore.authWithSpotify(accessToken, router);
        await router.push("/quiz");
    } catch (error) {
        console.error("SpotifyCallback.vue", "Erro ao obter informações do usuário:", error);
        await router.push("/");
    }

    isLoading.value = false;
});
</script>
