<template>
    <div class="app-container">
        <app-header :showIcon="showQuiz"></app-header>

        <Transition name="slide-down" @after-leave="showQuiz = !showQuiz">
            <div v-if="showResult" class="flex flex-col items-center pt-5">
                <img class="relative mx-auto mb-4" src="@/assets/images/icon-music.png" alt="" />

                <h1 class="uppercase leading-tight font-anton text-26 md:text-36 text-center mb-4">
                    Obrigado por <br class="md:hidden" />
                    participar!
                </h1>

                <img class="mb-2" src="@/assets/images/sep.png" />

                <p class="text-center max-w-[290px] md:max-w-[560px] mx-auto mb-5 md:mb-9 md:text-22">A playlist perfeita para você é...</p>

                <div class="max-w-[248px]">
                    <a :href="linkPlaylist" target="_blank">
                        <div class="p-3 bg-[#4564A9] rounded-md mb-4">
                            <img class="mb-3 rounded-md" :src="mostVotedPlayList.cover" alt="" />
                            <p class="text-white">
                                <span class="text-18" v-html="mostVotedPlayList.title"></span>
                                🌹 <br />
                                <span class="block pt-2 text-15">de Sony Music Brasil</span>
                            </p>
                        </div>
                    </a>
                    <a class="btn font-anton text-22 tracking-wider uppercase mb-4" :href="linkPlaylist" target="_blank">Confira agora! </a>

                    <h3 class="font-anton text-16 text-center tracking-wider uppercase mb-4">Compartilhe com os amigos</h3>

                    <ul class="flex justify-center gap-4 pb-8">
                        <li>
                            <a href="https://www.facebook.com/RobertoCarlosOficial/?locale=pt_BR" target="_blank" class="hover:opacity-80"><img src="@/assets/svgs/facebook.svg" alt="facebook" /></a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/robertocarlosoficial/" target="_blank" class="hover:opacity-80"><img src="@/assets/svgs/x.svg" alt="x" /></a>
                        </li>
                        <li>
                            <a href="https://x.com/robertocarlos?s=21" target="_blank" class="hover:opacity-80"><img src="@/assets/svgs/instagram.svg" alt="instagram" /></a>
                        </li>
                    </ul>

                    <button @click="restart()" class="btn font-anton text-22 tracking-wider uppercase mb-10 text-blue-600 bg-white">Recomeçar</button>
                </div>
            </div>
        </Transition>
        <Transition name="slide-down" @after-leave="showResult = !showResult">
            <div v-if="showQuiz" class="flex flex-col items-center pt-[70px] md:pt-[60px]">
                <div class="w-[190px] md:w-[220px] mb-8">
                    <counter :count="total" :max="questions.length"></counter>
                </div>
                <h1 class="uppercase leading-tight font-anton text-26 md:text-36 text-center mb-5 md:mb-8">Você prefere</h1>
                <GameCardsStack :cards="questions" @vote="addVotes" />

                <div class="h-[160px] md:h-[220px] mb-6"></div>

                <picture class="mb-1">
                    <source srcset="@/assets/images/directions__desktop.png" media="(min-width: 768px)" alt="" />
                    <img src="@/assets/images/directions.png" alt="" />
                </picture>

                <p class="text-center md:hidden max-w-[250px] mb-10">Arraste pra esquerda, direita ou clique na imagem que representa a sua escolha</p>
                <p class="text-center hidden md:block text-22 mb-10">Clique na imagem que representa a sua escolha</p>
            </div>
        </Transition>
    </div>
</template>

<script setup>
import axios from "axios";
import { ref, onMounted, onUnmounted, computed } from "vue";
import { useRouter } from "vue-router";

import AppHeader from "@/components/AppHeader.vue";
import GameCardsStack from "@/components/fato-ou-fake/GameCardsStack.vue";
import Counter from "@/components/fato-ou-fake/Counter.vue";

import { useAuthStore } from "@/stores/auth";
const authStore = useAuthStore();

const showResult = ref(false);
const showQuiz = ref(true);
const total = ref(0);

const questions = ref([]);
const playlists = ref([]);
const mostVotedPlayList = ref(null);

onMounted(async () => {
    addOverflowClass();
    const response = await axios(import.meta.env.VITE_DATA_URL);
    questions.value = response.data.questions;
    playlists.value = response.data.playlists;
});

onUnmounted(() => {
    removeOverflowClass();
    window.addEventListener("resize", () => {
        addOverflowClass();
        removeOverflowClass();
    });
});

const linkPlaylist = computed(() => {
    if (!authStore.user) return "";
    return mostVotedPlayList.value[`link_${authStore.user.authType}`];
});

/* métodos */

const addOverflowClass = () => {
    const body = document.querySelector("body");
    if (window.innerWidth < 768) {
        body.classList.add("no-scroll");
    } else {
        removeOverflowClass();
    }
};

const removeOverflowClass = () => {
    const body = document.querySelector("body");
    body.classList.remove("no-scroll");
};

const restart = () => {
    total.value = 0;
    showResult.value = false;
    mostVotedPlayList.value = null;

    playlists.value.forEach((item) => {
        item.votes = 0;
    });
    addOverflowClass();
};

const findItemWithMostVotes = () => {
    // Encontre o maior número de votos
    const maxVotes = Math.max(...playlists.value.map((item) => item.votes));

    // Filtrar os itens que têm o máximo de votos
    const itemsWithMaxVotes = playlists.value.filter((item) => item.votes === maxVotes);

    // Se houver apenas um item com o máximo de votos, retorne ele
    if (itemsWithMaxVotes.length === 1) {
        return itemsWithMaxVotes[0];
    }

    // Se houver empate, retorne um item aleatório entre os empatados
    const randomIndex = Math.floor(Math.random() * itemsWithMaxVotes.length);
    return itemsWithMaxVotes[randomIndex];
};

const tryToFinish = () => {
    if (questions.value.length !== total.value) {
        return;
    }
    mostVotedPlayList.value = findItemWithMostVotes();

    showQuiz.value = false;
    removeOverflowClass();
};

const addVotes = (payload) => {
    let slug = questions.value[payload.index][payload.direction];
    console.log(slug);
    playlists.value.find((pl, index) => {
        if (pl.slug == slug) {
            pl.votes++;
        }
    });
    removeCardFromDeck();
    tryToFinish();
};

const removeCardFromDeck = () => {
    total.value++;
};

const router = useRouter();
//  await router.push("/lists");
</script>

<style>
.no-scroll {
    touch-action: none !important;
    overflow: hidden !important;
}

/*  */
.slide-down-enter-active,
.slide-down-leave-active {
    transition: all 0.25s ease-out;
}

.slide-down-enter-from {
    opacity: 0;
    transform: translateY(-30px);
}

.slide-down-leave-to {
    opacity: 0;
    transform: translateY(30px);
}

/* ----------------------------------------- */

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}

/* --------------------------------------- */

.bounce-enter-active {
    animation: bounce-in 0.5s;
}
.bounce-leave-active {
    animation: bounce-in 0.5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.25);
    }
    100% {
        transform: scale(1);
    }
}

/* --------------------------------------- */

.slide-fade-enter-active {
    transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
</style>
